import srcBeBetterBettors from '@/pages/(auth)/register/components/images/bebetterbettors.jpg';
import srcCirclesOff from '@/pages/(auth)/register/components/images/circlesoff.jpg';
import srcVSIN from '@/pages/(auth)/register/components/images/vsin.png';

interface Link {
  name: string;
  href: string;
}

type Podcast = { title: string; description: string; links?: Link[]; imgPath: string };

const PODCASTS: Podcast[] = [
  {
    title: 'Edward on Be Better Bettors podcast',
    description:
      'Spanky interviews RAS founder Edward who details the journey of RAS transforming from a solo operation into one of the most respected groups in the world.',
    links: [
      {
        name: 'Spotify',
        href: 'https://open.spotify.com/show/09TrmPCz6a6jGLOlk2T3X5',
      },
      {
        name: 'Apple Podcasts',
        href: 'https://podcasts.apple.com/no/podcast/ed-golden-right-angle-sports-interview/id1493902736?i=1000498863860',
      },
      {
        name: 'Google Podcasts',
        href: 'https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy8xMTI4YjE0Yy9wb2RjYXN0L3Jzcw==',
      },
    ],
    imgPath: srcBeBetterBettors,
  },
  {
    title: "Edward's on Circles Off podcast",
    description:
      'Johnny from betstamp and Rob Pizzola are joined by Edward to discuss building a pick-selling service, maintaining a reputation, keeping your edge and more.',
    links: [
      {
        name: 'YouTube',
        href: 'https://www.youtube.com/watch?v=mj40UN2Wa0M',
      },
      {
        name: 'Spotify',
        href: 'https://open.spotify.com/episode/2D2ZCKa8jX4fPINrYHhcc8?si=eip_uZ88Qp-BEnFkYqcsAQ',
      },
      {
        name: 'Apple Podcasts',
        href: 'https://podcasts.apple.com/us/podcast/episode-100-ed-golden-joins-for-a-discussion/id1552575702?i=1000611780662',
      },
    ],
    imgPath: srcCirclesOff,
  },
  {
    title: "Edward on VSiN's A Numbers Game",
    description:
      "Edward joins VSIN's Gill Alexander, offering rare insights into the world of sports betting and the evolution of his influential service.",
    links: [
      {
        name: 'YouTube',
        href: 'https://www.youtube.com/watch?v=Xkeei7f_gPM',
      },
    ],
    imgPath: srcVSIN,
  },
];

export default function Podcasts() {
  return (
    <div className="px-2">
      <div className="mx-auto mt-12 w-full divide-y divide-white/10 bg-white/5 p-4 ring-1 ring-white/10 sm:mt-24 sm:w-fit sm:p-12">
        {PODCASTS.map(({ title, description, links, imgPath }) => (
          <div
            className="flex flex-col space-y-4 py-4 sm:flex-row sm:space-x-8 sm:space-y-0"
            key={title}
          >
            <img src={imgPath} height={96} width={96} className="size-24 ring-1 ring-white/20" />
            <div className="space-y-2">
              <div className="text-lg">{title}</div>
              <p className="max-w-prose text-sm leading-6 text-zinc-400">{description}</p>
              <ul className="flex flex-col space-y-2 text-xs font-medium sm:mt-0 sm:flex-row sm:space-x-8 sm:space-y-0">
                {links &&
                  links.map(({ name, href }) => (
                    <li key={name}>
                      <a href={href} target="_blank" rel="noreferrer">
                        {name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
